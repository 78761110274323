/**
 * Created by anssilehtonen on 08/04/16.
 */
(function($) {

    $(document).ready(function() {

        function getTablesHeight() {
            $('table').each(function() {
              var snippet_height = $(this).find('.featurette__snippet').outerHeight();
              var summary_height = $(this).find('.featurette__summary').outerHeight();
              var right_col_height = parseInt(snippet_height) + parseInt(summary_height);
              $(this).find('.carousel-td').height(right_col_height);
            });

            var service_height = $('.service').outerHeight();
            service_height = parseInt(service_height)*2;
            $('.service-popup').each(function() {
            $(this).outerHeight(service_height);
            });
        }

        $('.service').each(function() {
            var target_to_hide = $(this).data('target');
            $(target_to_hide).hide();
        });

        $('.service-popup__close').click(function() {
            $(this).parent().fadeOut(300);
        });

        $('.service.popup-link').click(function(event) {
            event.preventDefault ? event.preventDefault() : event.returnValue = false;
            var target_to_show = $(this).data('target');
            //console.log(target_to_show);
            $(target_to_show).fadeIn(300);
        });

        $('.hero .carousel').on('init', function(event, slick){
            if($('.hero .carousel .slick-current video').length>0) {
                $('.hero .carousel .slick-current video').get(0).play();
            }
            if (slick.slideCount < 2) {
                $(this).find('.slick-dots').hide();
            }
        });
    

        $('.hero .carousel').slick({
            autoplay: false,
            autoplaySpeed: 6000,
            dots: true,
            infinite: true,
            speed: 600,
            fade: true,
            cssEase: 'ease-in-out',
            slidesToShow: 1,
            prevArrow : '<button type="button" class="slick-prev glyphicon glyphicon-chevron-left"></button>',
            nextArrow : '<button type="button" class="slick-next glyphicon glyphicon-chevron-right"></button>',
            responsive: [
                {
                  breakpoint: 769,
                  settings: {
                    autoplay: false,
                    arrows: false,
                    infinite: false,
                    dots: false,
                    swipe: false
                  }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
              ]
        });

        $('.hero .carousel').on('afterChange', function(event, slick, currentSlide){
            $('.hero .carousel video').each(function(index, el) {
                $(el).get(0).pause();
            });
            if($('.hero .carousel .slick-current video').length>0) {
                $('.hero .carousel .slick-current video').get(0).play();
            }
        });

        $('.hero .carousel video').click(function(event) {
            if(!$(this)[0].paused) {
                $(this).get(0).pause();
            } else {
                $(this).get(0).play();
            }
        });

        $('.featurette .carousel').on('init', function(event, slick){
            if($('.featurette .carousel .slick-current video').length>0) {
                $('.featurette .carousel .slick-current video').get(0).play();
            }
            if (slick.slideCount < 2) {
                $(this).find('.slick-dots').hide();
            }
        });

        $('.featurette .carousel').slick({
            dots: true,
            infinite: true,
            speed: 300,
            useTransform: true,
            cssEase: 'ease-in-out',
            slidesToShow: 1,
            prevArrow : '<button type="button" class="slick-prev glyphicon glyphicon-chevron-left"></button>',
            nextArrow : '<button type="button" class="slick-next glyphicon glyphicon-chevron-right"></button>',
              responsive: [
                {
                  breakpoint: 769,
                  settings: {
                    arrows: false,
                    infinite: false,
                    dots: false,
                    swipe: false
                  }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
              ]
        });

        $('.featurette .carousel').on('afterChange', function(event, slick, currentSlide){
            $(this).find('video').each(function(index, el) {
                $(el).get(0).pause();
            });
            console.log($(this));
            if($(this).find('.slick-current video').length>0) {
                $(this).find('.slick-current video').get(0).play();
            }
        });

        function winOnResize() {
        getTablesHeight();
        }

        $(window).on('resize', winOnResize);

        getTablesHeight();

        var navID = '#header__nav';
        $(navID).on('show.bs.collapse', function () {
            $('.navbar-toggle[data-target="'+navID+'"]').find('.hamburger').toggleClass('is-active', true);
        }).on('hide.bs.collapse', function () {
            $('.navbar-toggle[data-target="'+navID+'"]').find('.hamburger').toggleClass('is-active', false);
        });

        $("img.lazy").lazyload({
            threshold : -100
        });

        $("div.lazy").lazyload();

        $("div.lazy-hero").lazyload({
            event : "sporty"
        });
        
    });

    $('a[href*="#"]:not([href="#"])').click(function() {
    if($('.navbar-toggle').css('display') !=='none'){
            $(".navbar-toggle").trigger( "click" );
        }
    if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        history.pushState(null, null, target.selector);
        $('html, body').animate({
          scrollTop: target.offset().top - 74
        }, 1000);
        return false;
      }
    }
    });

    function init() {

        window.addEventListener('scroll', function(e){
            var distanceY = window.pageYOffset || document.documentElement.scrollTop,
                shrinkOn = 300,
                header = document.querySelector(".navbar");
            if (distanceY > shrinkOn) {
                classie.add(header,"smaller");
            } else {
                if (classie.has(header,"smaller")) {
                    classie.remove(header,"smaller");
                }
            }
        });
    }
    window.onload = init();

}(jQuery));